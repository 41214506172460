<template>
  <div id="teaxt-title">
    <div class="container">
        <div class="teaxt-title">
            <div class="teaxt-title-en">{{en}}</div>
            <div class="teaxt-title-ch">{{ch}}</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextTitle",
  props:['en','ch']
};
</script>

<style lang="scss" scoped>
.teaxt-title {
    width: 500px;
    height: 128px;
    margin: 0 auto;
    margin-top: 20px;
    background: url(./images/bg.png) no-repeat;
    background-position: center;
    &-en {
        text-align: center;
        font-size:36px;
        font-family: Arial;
        color: #d1d1d1;
        padding-top: 20px;
    }
    &-ch {
        text-align: center;
        font-size: 30px;
        color: #333;
    }
}

</style>
