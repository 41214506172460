<template>
  <div>
    <!-- 请登录注册条 -->
    <!-- <div id="header-login">
      <div class="container">
        <div class="flex-box">
          <span>欢迎进入页面,&nbsp;&nbsp;</span>
          <router-link to="/login" class="login">请登录</router-link>
          <router-link to="/register" class="register">注册</router-link>
        </div>
      </div>
    </div> -->
    <!-- 头部导航条 -->
    <div class="container">
      <div class="header-nav">
        <div class="nav-logo">
            <!-- <div style="margin-top: 50px;">上海传晟律师事务所</div> -->
          <img src="./images/传晟LOGO.jpg" alt="" />
        </div>

        <router-link to="/home" class="nav-item">
          <div style="line-height: 18px">首页</div>
          <div style="line-height: 24px">HOME</div>
        </router-link>
        <router-link to="/about" class="nav-item">
          <div style="line-height: 18px">关于我们</div>
          <div style="line-height: 24px">ABOUT US</div>
        </router-link>
        <router-link to="/major" class="nav-item">
          <div style="line-height: 18px">专业领域</div>
          <div style="line-height: 24px">MAJOR</div>
        </router-link>
        <router-link to="/team" class="nav-item">
          <div style="line-height: 18px">专业人员</div>
          <div style="line-height: 24px">TEAM</div>
        </router-link>
        <router-link to="/news" class="nav-item">
          <div style="line-height: 18px">新闻动态</div>
          <div style="line-height: 24px">NEWS</div>
        </router-link>
        <router-link to="/share" class="nav-item">
          <div style="line-height: 18px">案例分享</div>
          <div style="line-height: 24px">CASE</div>
        </router-link>
        <router-link to="/recruit" class="nav-item">
          <div style="line-height: 18px">招贤纳士</div>
          <div style="line-height: 24px">RECRUIT</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="scss" scoped>
.container {
  margin-left: auto;
  margin-right: auto;
  width: 1200px;
}
// 请登录注册条
#header-login {
  height: 50px;
  width: 100%;
  background-color: #1e1e1e;

  .container {
    margin-left: auto;
    margin-right: auto;
    width: 1200px;
    line-height: 50px;

    .flex-box {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;

      span {
        color: #9c9c9c;
      }
      .login {
        margin-right: 15px;
        color: #fff;
      }
      .register {
        color: red;
      }
    }
  }
}
// 头部导航条
.header-nav {
  display: flex;
  justify-content: space-around;
  height: 97px;
  text-align: center;
  align-items: center;
  //   background-color: #bfa;
  .nav-logo{
    // height: 152px;
    // width: 208px;
    // background-color: #364795;
  }
  .nav-item {
    position: relative;
    margin-top: 15px;
  }
  .nav-item :first-child {
    font-weight: bold;
    font-family: "思源黑体" !important;
    font-size: 16px;
  }
  .nav-item :nth-child(2) {
    font-family: "Arial";
    font-size: 12px;
    // color: #515151;
  }
  .nav-item:hover {
    cursor: pointer;
    color: #364795;
  }
}

// .router-link-exact-active {
//     color: #364795;
// }
.router-link-active {
    color: #364795;
}

.router-link-active:not(.router-link-active:nth-of-type(1)):before {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
    top: -55px;
    z-index: 999;
    display: inline-block;
    content: '';
    width: 66px;
    height: 2px;
    background-color: #364795;
}


</style>
