const Home = () => import(/* webpackChunkName:'home'*/"@/views/Home");
const Login = () => import(/* webpackChunkName:'login'*/"@/views/Login");
const Register = () => import(/* webpackChunkName:'register'*/"@/views/Register");
const About = () => import(/* webpackChunkName:'about'*/"@/views/About");
const Team = () => import(/* webpackChunkName:'team'*/"@/views/Team");    
const Recruit = () => import(/* webpackChunkName:'team'*/"@/views/Recruit");    
const News = () => import(/* webpackChunkName:'team'*/"@/views/News");    
const Share = () => import(/* webpackChunkName:'team'*/"@/views/share");    
const ShareDetail = () => import(/* webpackChunkName:'team'*/"@/views/share/detail");    
const NewsMain = () => import(/* webpackChunkName:'team'*/"@/views/News/NewsMain"); 
const Newsdetail = () => import(/* webpackChunkName:'team'*/"@/views/News/Newsdetail");
const Major = () => import(/* webpackChunkName:'*/"@/views/Major");

const liuyongfu = () => import(/* webpackChunkName:'*/"@/views/Team/number");
const Teammain = () => import(/* webpackChunkName:'*/"@/views/Team/Teammain");
const Notice = () => import(/* webpackChunkName:'*/"@/views/notice");
export default [
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/about',
        name: 'about',
        component: About
    },
    {
        path:'/notice',
        name:'notice',
        component: Notice
    },
    {
        path: '/major',
        name: 'major',
        component: Major
    },
    {
        path: '/team',
        // name: 'team',
        component: Team,
        children:[
            {
                path:'',
                component: Teammain,
            },
            {
                path:'number',
                component:liuyongfu,
            }
        ]
    },
    {
        path: '/news',
        component: News,
        children: [
            {
                path:'',
                name:'Defaultnews',
                component: NewsMain,
            },
            {
                path:'detaile',
                name: 'detaile',
                component: Newsdetail,
            }
        ]

    },
    {
        path: '/share',
        name: 'share',
        component: Share
    },
    {
        path: '/share/detail',
        name: 'share-detail',
        component: ShareDetail
    },
    {
        path: '/recruit',
        name: 'recruit',
        component: Recruit
    },
    {
        path: '/login',
        name: 'login',
        component:Login,
        meta: {
            isHidden: true,
        },
    },
    {
        path:'/register',
        name:'register',
        component:Register,
        meta: {
            isHidden: true,
        },
    },
    {
        path:'*',
        redirect:'/home',
    },
]