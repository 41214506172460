import Vue from "vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 引入地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, { ak: '28080955' })
import TextTitle from '@/components/TextTitle'

Vue.use(ElementUI);
Vue.config.productionTip = false;
// 全局组件注册
Vue.component('TextTitle',TextTitle)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
