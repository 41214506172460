<template>
  <div id="app">
    <Header v-show="!$route.meta.isHidden" />
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
  name:'Home',
  components:{
    Header,
    Footer
  }
}
</script>

<style lang="scss">

</style>
