<template>
  <div class="footer">
    <div class="container">
      <div>
        <img height="140px" width="140px" src="@/assets/qrcode.png" alt="">
      </div>

      <div class="footer_nav_wrape">
        <div class="nav_link">
          <div class="nav_link_1">上海传晟律师事务所</div>
          <div class="nav_link_2" >
            <router-link to="/home">首页</router-link>
            <router-link to="/about">关于我们</router-link>
            <router-link to="/major">专业领域</router-link>
            <router-link to="/team">专业人员</router-link>
            <router-link to="/news">新闻动态</router-link>
            <router-link to="/share">案例分享</router-link>
            <router-link to="/recruit">招贤纳士</router-link>
          </div>
        </div>
        <div class="line"></div>
        <div class="footer_concat ">
            <span>联系地址: 上海市浦东新区，新加坡大华银行7楼</span>
            <span>联系电话: 15300808637</span>
            <span>邮箱: wanghongwei@chuanshenglaw.cc</span>
        </div>
        <div class="footer_concat ">
            <span @click="routeTo">法律声明</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    routeTo() {
      this.$router.push("/notice")
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 180px;
  margin: 0 auto;
  background-color: #25292c;
  .container {
    display: inherit;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    .footer_nav_wrape {
      margin-left: 20px;
      width: 922px;
      .nav_link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        line-height: 50px;
        .nav_link_1 {
          font-size: 24px;
          font-weight: bold;
          color: #fff;
          letter-spacing: 9px;
        }
        .nav_link_2 {
          font-size: 14px;
          color: #b6b6b7;
          line-height: 50px;
          flex-wrap: nowrap;
          a:link {
            margin: 0 10px;
            color: #fff;
          }
        }
      }
      .line {
        width: auto; 
        height: 2px;
        background:#686c6e; 
        margin: 10px 0; 
      }
    }
  }
}

.footer_concat {
    margin:0 auto;
    height: 32px;
    font-size: 14px;
    color: #b6b6b7;
    display: flex;
    justify-content: space-between;

    span {
      cursor: pointer;
      &:hover {
        color: #ebebeb;
      }
    }
}
</style>
